'use client';
import { AdvertisingWidgetMobile, HeaderWidgetMobile } from '@widgets/mobile';
import { observer } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';
import React from 'react';

import { useUser } from '@/shared/api/user/useUser';
import { useStore } from '@/store/context';

export const WithHeaderLayout = () => {
  const pathname = usePathname();

  if (
    pathname.includes('cart') ||
    pathname.includes('inventory') ||
    pathname.includes('drop') ||
    RegExp('^\\/[a-z]{2}\\/shop$').test(pathname) ||
    RegExp('^\\/[a-z]{2}\\/faq(\\/[a-z-]*)$').test(pathname) ||
    RegExp('^\\/[a-z]{2}\\/profile/history(\\/[a-z-]*)$').test(pathname) ||
    RegExp('^\\/[a-z]{2}\\/categories(\\/[a-z-]*)$').test(pathname)
  ) {
    return <div id={'header-portal'}></div>;
  }

  return (
    <>
      <WithHeaderLayout.Advertising />
      <HeaderWidgetMobile />
    </>
  );
};

WithHeaderLayout.Advertising = observer(() => {
  //const pathname = usePathname();
  const { user } = useUser();

  return user ? <AdvertisingWidgetMobile /> : null;
});
